<template>
  <div class="main">
    <!-- header end-->
    <!-- body start -->
    <div class="bodybox">
      <div class="bigtitle">
        <span>企业服务</span>
      </div>
      <div class="textbox">
        <div class="list" v-for="(item, i) in list" :key="i">
          <div class="list_top">
            <div class="img_box" :style="'background-color:' + item.color">
              <img src="../assets/img/san.png" alt="" />
            </div>
            <div class="inforbox">{{ item.Name }}</div>
          </div>
          <div class="list_bottom">
            <div v-if="item.ServeList.length">
              <div
                class="bottom_list"
                v-for="(items, index) in item.ServeList"
                :key="index"
              >
                <div class="list_title">{{ items.Title }}</div>
                <div class="list_infor">{{ items.CompanyName }}</div>
              </div>
            </div>
            <div v-else>
              <span class="notens">暂无推荐，请点击 "了解更多"</span>
            </div>

            <div class="btn_box">
              <div class="btn" @click="gettypeInfor(item, i)">了解更多</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- body end -->
  </div>
</template>

<script>
// 公共样式

// import timeFormat from "../util/time.js";
import "../assets/css/common.css";
export default {
  data() {
    return {
      arr: ["#105C30", "#A62F88", "#CD540D", "#411A5B", "#A87315", "#0EA852"],
      list: [],
    };
  },

  methods: {
    //   服务详情
    gettypeInfor(id, index) {
      console.log(index);
      this.$router.push({
        path: "/enterpriseServices",
        query: { item: id, index: index },
      });
    },

    GetServeList() {
      let that = this;
      let arr = that.arr;
      that
        .$post("/api/Serve/CateList")
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          if (data.Result && list.length) {
            list.forEach((val, i) => {
              val.color = arr[i % 6];
            });
            that.list = list;
          }
        })

        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    var that = this;
    that.GetServeList();
  },
};
</script>


<style scoped  lang="scss">
.bigtitle {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: inline-block;
    height: 40px;
    line-height: 4px;
    font-weight: 700;
    font-size: 32px;
    padding: 20px;
    // border-left: 4px solid #045325;
    color: #000;
  }
}
.textbox {
  // background-color: red;
  width: 1220px;
  margin-left: 50%;
  transform: translateX(-50%);
  min-height: 300px;
  // padding-bottom: 150px;
  .list {
    float: left;
    width: 380px;
    height: 420px;
    background-color: #fff;
    box-shadow: 3px 8px 5px #eee;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px solid #eee;
    padding: 10px;
    box-sizing: border-box;

    .list_top {
      position: relative;
      height: 160px;
      // background-color: red;
      .img_box {
        top: 24px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        height: 66px;
        width: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 36px;
          width: 36px;
          transform: rotate(15deg);
        }
      }
      .inforbox {
        position: absolute;
        top: 100px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.8);
      }
    }
    .list_bottom {
      position: relative;
      height: 220px;
      width: 100%;
      border-top: 2px solid #eee;
      .notens {
        position: absolute;
        padding: 0 30px;
        box-sizing: border-box;
        text-align: center;
        width: 100%;
        top: 60px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
      }
      .bottom_list {
        float: left;
        width: 50%;
        padding: 20px 5px;
        box-sizing: border-box;
        .list_title {
          height: 30px;
          line-height: 30px;
          font-weight: 550;
          font-size: 14px;
          color: #000;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .list_infor {
          height: 20px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .btn_box {
        position: absolute;
        bottom: 0;
        height: 46px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
          display: inline-block;
          height: 46px;
          line-height: 46px;
          width: 166px;
          background-color: #ebeef5;
          color: #a5a5a7;
          cursor: pointer;
          font-size: 16px;
          text-align: center;
          border-radius: 2px;
        }
        .btn:hover {
          background-color: #115c31;
          color: #fff;
        }
      }
    }
  }

  .list:hover {
    box-shadow: 0 10px 50px #ddd;
  }
}
@media (max-width: 500px) {
  .textbox {
    // background-color: red;
    width: 100%;

    .list {
      float: left;
      width: 96%;
      height: 420px;
      margin-left: 2%;

      .list_top {
        position: relative;
        height: 160px;
        // background-color: red;
        .img_box {
          top: 18px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 50%;
          height: 66px;
          width: 66px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 36px;
            width: 36px;
          }
        }
        .inforbox {
          position: absolute;
          top: 100px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          width: 100%;
          font-weight: 700;
          font-size: 20px;
          color: rgba(0, 0, 0, 0.8);
        }
      }
      .list_bottom {
        position: relative;
        height: 220px;
        width: 100%;
        border-top: 2px solid #eee;
        .bottom_list {
          float: left;
          width: 50%;
          padding: 20px 5px;
          box-sizing: border-box;
          .list_title {
            height: 30px;
            line-height: 30px;
            font-weight: 550;
            font-size: 14px;
            color: #000;
          }
          .list_infor {
            height: 20px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
          }
        }

        .btn_box {
          position: absolute;
          bottom: 0;
          height: 40px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .btn {
            display: inline-block;
            height: 35px;
            line-height: 35px;
            padding: 0 30px;
            background-color: #eee;
            color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            font-size: 14px;
            border-radius: 2px;
          }
          .btn:hover {
            background-color: #115c31;
            color: #fff;
          }
        }
      }
    }

    .list:hover {
      box-shadow: 0 10px 20px #bbb;
    }
  }
}
</style>